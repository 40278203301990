
import { createTheme } from '@mui/material/styles';

const themeMuiV5 = createTheme({
    palette: {
      primary: {
          main: '#244f9d'
      },
      /*inactive: {
          main: '#444444'
      },*/
      secondary: {
          main: '#af4731'
      },
      success: { 
          main: '#20a842'
      },
      error: {
          main: '#af4731'
      }/*,
      grey: {
          main: '#efefef'
      }*/
  },
    breakpoints: {
      keys: [
        'xs', 'sm', 'md', 'lg', 'xl'
    ],
      values: {
        xs: 0,
        sm: 768,
        md: 900,
        lg: 1200,
        xl: 1536
      },
    },
  });

export default themeMuiV5;